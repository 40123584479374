<template>
  <div class="px-2 py-5">
    <h1 class="title">Prueba de Escala de calidad de vida WHOQOL-BREF</h1>
    <b-message type="is-info">
      Este cuestionario sirve para conocer su opinión acerca de su calidad de
      vida, su salud y otras áreas de su vida. Por favor conteste todas las
      preguntas. Si no está seguro/a de qué respuesta dar a una pregunta, escoja
      la que le parezca más apropiada. A veces, ésta puede ser la primera
      respuesta que le viene a la cabeza.
    </b-message>

    <div class="block">
      <span>
        1. ¿Cómo calificaría su calidad de vida?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-left">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.WHOQOL_BREF_P1"
            :native-value="answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy mala
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.WHOQOL_BREF_P1"
            :native-value="answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Regular
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.WHOQOL_BREF_P1"
            :native-value="answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.WHOQOL_BREF_P1"
            :native-value="answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante buena
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.WHOQOL_BREF_P1"
            :native-value="answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy buena
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. ¿Cuán satisfecho/a está con su salud?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.WHOQOL_BREF_P2"
            :native-value="answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.WHOQOL_BREF_P2"
            :native-value="answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.WHOQOL_BREF_P2"
            :native-value="answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.WHOQOL_BREF_P2"
            :native-value="answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.WHOQOL_BREF_P2"
            :native-value="answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. ¿Hasta qué punto piensa que el dolor (físico) le impide hacer lo que
        necesita?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.WHOQOL_BREF_P3"
            :native-value="answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.WHOQOL_BREF_P3"
            :native-value="answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.WHOQOL_BREF_P3"
            :native-value="answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.WHOQOL_BREF_P3"
            :native-value="answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.WHOQOL_BREF_P3"
            :native-value="answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        4. ¿En qué grado necesita de un tratamiento médico para funcionar en su
        vida diaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.WHOQOL_BREF_P4"
            :native-value="answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.WHOQOL_BREF_P4"
            :native-value="answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.WHOQOL_BREF_P4"
            :native-value="answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.WHOQOL_BREF_P4"
            :native-value="answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.WHOQOL_BREF_P4"
            :native-value="answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5. ¿Cuánto disfruta de la vida?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.WHOQOL_BREF_P5"
            :native-value="answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.WHOQOL_BREF_P5"
            :native-value="answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.WHOQOL_BREF_P5"
            :native-value="answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.WHOQOL_BREF_P5"
            :native-value="answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.WHOQOL_BREF_P5"
            :native-value="answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6. ¿Hasta qué punto siente que su vida tiene sentido?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.WHOQOL_BREF_P6"
            :native-value="answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.WHOQOL_BREF_P6"
            :native-value="answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.WHOQOL_BREF_P6"
            :native-value="answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.WHOQOL_BREF_P6"
            :native-value="answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.WHOQOL_BREF_P6"
            :native-value="answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7. ¿Cuál es su capacidad de concentración?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.WHOQOL_BREF_P7"
            :native-value="answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.WHOQOL_BREF_P7"
            :native-value="answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.WHOQOL_BREF_P7"
            :native-value="answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.WHOQOL_BREF_P7"
            :native-value="answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.WHOQOL_BREF_P7"
            :native-value="answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        8. ¿Cuánta seguridad siente en su vida diaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.WHOQOL_BREF_P8"
            :native-value="answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.WHOQOL_BREF_P8"
            :native-value="answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.WHOQOL_BREF_P8"
            :native-value="answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.WHOQOL_BREF_P8"
            :native-value="answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.WHOQOL_BREF_P8"
            :native-value="answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        9. ¿Cómo de saludable es el ambiente fisico a su alrededor?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.WHOQOL_BREF_P9"
            :native-value="answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.WHOQOL_BREF_P9"
            :native-value="answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.WHOQOL_BREF_P9"
            :native-value="answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.WHOQOL_BREF_P9"
            :native-value="answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.WHOQOL_BREF_P9"
            :native-value="answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Extremadamente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        10. ¿Tiene energía suficiente para la vida diaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.WHOQOL_BREF_P10"
            :native-value="answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.WHOQOL_BREF_P10"
            :native-value="answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.WHOQOL_BREF_P10"
            :native-value="answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.WHOQOL_BREF_P10"
            :native-value="answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.WHOQOL_BREF_P10"
            :native-value="answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        11. ¿Es capaz de aceptar su apariencia fisica?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.WHOQOL_BREF_P11"
            :native-value="answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.WHOQOL_BREF_P11"
            :native-value="answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.WHOQOL_BREF_P11"
            :native-value="answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.WHOQOL_BREF_P11"
            :native-value="answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.WHOQOL_BREF_P11"
            :native-value="answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        12. ¿Tiene suficiente dinero para cubrir sus necesidades?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.WHOQOL_BREF_P12"
            :native-value="answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.WHOQOL_BREF_P12"
            :native-value="answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.WHOQOL_BREF_P12"
            :native-value="answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.WHOQOL_BREF_P12"
            :native-value="answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.WHOQOL_BREF_P12"
            :native-value="answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        13. ¿Dispone de la información que necesita para su vida diaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.WHOQOL_BREF_P13"
            :native-value="answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.WHOQOL_BREF_P13"
            :native-value="answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.WHOQOL_BREF_P13"
            :native-value="answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.WHOQOL_BREF_P13"
            :native-value="answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThirteen"
            :name="questions.WHOQOL_BREF_P13"
            :native-value="answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        14. ¿Hasta qué punto tiene oportunidad de realizar actividades de ocio?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.WHOQOL_BREF_P14"
            :native-value="answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.WHOQOL_BREF_P14"
            :native-value="answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.WHOQOL_BREF_P14"
            :native-value="answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.WHOQOL_BREF_P14"
            :native-value="answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFouthteen"
            :name="questions.WHOQOL_BREF_P14"
            :native-value="answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        15. ¿Es capaz de desplazarse de un lugar a otro?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.WHOQOL_BREF_P15"
            :native-value="answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.WHOQOL_BREF_P15"
            :native-value="answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.WHOQOL_BREF_P15"
            :native-value="answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.WHOQOL_BREF_P15"
            :native-value="answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFifteen"
            :name="questions.WHOQOL_BREF_P15"
            :native-value="answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Totalmente
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        16. ¿Cómo de satisfecho/a está con su sueño?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.WHOQOL_BREF_P16"
            :native-value="answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.WHOQOL_BREF_P16"
            :native-value="answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.WHOQOL_BREF_P16"
            :native-value="answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.WHOQOL_BREF_P16"
            :native-value="answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSixteen"
            :name="questions.WHOQOL_BREF_P16"
            :native-value="answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        17. ¿Cómo de satisfecho/a está con su habilidad para realizar sus
        actividades de la vida diaria?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeventeen"
            :name="questions.WHOQOL_BREF_P17"
            :native-value="answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeventeen"
            :name="questions.WHOQOL_BREF_P17"
            :native-value="answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeventeen"
            :name="questions.WHOQOL_BREF_P17"
            :native-value="answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeventeen"
            :name="questions.WHOQOL_BREF_P17"
            :native-value="answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeventeen"
            :name="questions.WHOQOL_BREF_P17"
            :native-value="answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        18. ¿Cómo de satisfecho/a está con su capacidad de trabajo?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEighteen"
            :name="questions.WHOQOL_BREF_P18"
            :native-value="answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEighteen"
            :name="questions.WHOQOL_BREF_P18"
            :native-value="answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEighteen"
            :name="questions.WHOQOL_BREF_P18"
            :native-value="answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEighteen"
            :name="questions.WHOQOL_BREF_P18"
            :native-value="answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerEighteen"
            :name="questions.WHOQOL_BREF_P18"
            :native-value="answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        19. ¿Cómo de satisfecho/a está de si mismo?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNineteen"
            :name="questions.WHOQOL_BREF_P19"
            :native-value="answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNineteen"
            :name="questions.WHOQOL_BREF_P19"
            :native-value="answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNineteen"
            :name="questions.WHOQOL_BREF_P19"
            :native-value="answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNineteen"
            :name="questions.WHOQOL_BREF_P19"
            :native-value="answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerNineteen"
            :name="questions.WHOQOL_BREF_P19"
            :native-value="answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        20. ¿Cómo de satisfecho/a está con sus relaciones personales?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwenty"
            :name="questions.WHOQOL_BREF_P20"
            :native-value="answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwenty"
            :name="questions.WHOQOL_BREF_P20"
            :native-value="answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwenty"
            :name="questions.WHOQOL_BREF_P20"
            :native-value="answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwenty"
            :name="questions.WHOQOL_BREF_P20"
            :native-value="answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwenty"
            :name="questions.WHOQOL_BREF_P20"
            :native-value="answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        21. ¿Cómo de satisfecho/a está con su vida sexual?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyOne"
            :name="questions.WHOQOL_BREF_P21"
            :native-value="answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyOne"
            :name="questions.WHOQOL_BREF_P21"
            :native-value="answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyOne"
            :name="questions.WHOQOL_BREF_P21"
            :native-value="answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyOne"
            :name="questions.WHOQOL_BREF_P21"
            :native-value="answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentyOne"
            :name="questions.WHOQOL_BREF_P21"
            :native-value="answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        22. ¿Cómo de satisfecho/a está con el apoyo que obtiene de sus
        amigos/as?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentytwo"
            :name="questions.WHOQOL_BREF_P22"
            :native-value="answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentytwo"
            :name="questions.WHOQOL_BREF_P22"
            :native-value="answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentytwo"
            :name="questions.WHOQOL_BREF_P22"
            :native-value="answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentytwo"
            :name="questions.WHOQOL_BREF_P22"
            :native-value="answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentytwo"
            :name="questions.WHOQOL_BREF_P22"
            :native-value="answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        23. ¿Cómo de satisfecho/a está de las condiciones del lugar donde vive?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyThree"
            :name="questions.WHOQOL_BREF_P23"
            :native-value="answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyThree"
            :name="questions.WHOQOL_BREF_P23"
            :native-value="answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyThree"
            :name="questions.WHOQOL_BREF_P23"
            :native-value="answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyThree"
            :name="questions.WHOQOL_BREF_P23"
            :native-value="answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentyThree"
            :name="questions.WHOQOL_BREF_P23"
            :native-value="answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        24. ¿Cómo de satisfecho/a está con el acceso que tiene a los servicios
        sanitarios?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFour"
            :name="questions.WHOQOL_BREF_P24"
            :native-value="answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy insatisfecho/a
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFour"
            :name="questions.WHOQOL_BREF_P24"
            :native-value="answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFour"
            :name="questions.WHOQOL_BREF_P24"
            :native-value="answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFour"
            :name="questions.WHOQOL_BREF_P24"
            :native-value="answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentyFour"
            :name="questions.WHOQOL_BREF_P24"
            :native-value="answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        25. ¿Cómo de satisfecho/a está con los servicios de transporte de su
        zona?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFive"
            :name="questions.WHOQOL_BREF_P25"
            :native-value="answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFive"
            :name="questions.WHOQOL_BREF_P25"
            :native-value="answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Insatisafecho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFive"
            :name="questions.WHOQOL_BREF_P25"
            :native-value="answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Lo normal
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentyFive"
            :name="questions.WHOQOL_BREF_P25"
            :native-value="answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bastante satisfecho/a
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentyFive"
            :name="questions.WHOQOL_BREF_P25"
            :native-value="answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muy satisfecho/a
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        26. ¿Con qué frecuencia tiene sentimientos negativos, tales como
        tristeza, desesperanza, ansiedad, o depresión?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentySix"
            :name="questions.WHOQOL_BREF_P26"
            :native-value="answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nunca
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentySix"
            :name="questions.WHOQOL_BREF_P26"
            :native-value="answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Raramente
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentySix"
            :name="questions.WHOQOL_BREF_P26"
            :native-value="answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Moderadamente
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwentySix"
            :name="questions.WHOQOL_BREF_P26"
            :native-value="answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Frecuentemente
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwentySix"
            :name="questions.WHOQOL_BREF_P26"
            :native-value="answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Siempre
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="columns">
        <div class="column is-one-third">
          <b-field
            label="Ponderado dimensión SALUD FISICA:"
            message="Este resultado debe interpretarse en una escala de 0 a 100 puntos. Siendo 0 puntos la peor SALUD FISICA y 100 puntos la mejor"
            type="is-dark"
          >
            <b-input type="text" v-model="physicalScore" disabled> </b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <b-field
            label="Ponderado dimensión PSICOLOGICA:"
            message="Este resultado debe interpretarse en una escala de 0 a 100 puntos. Siendo 0 puntos la peor puntuacion PSICOLOGICA y 100 puntos la mejor"
            type="is-dark"
          >
            <b-input type="text" v-model="psychologicalScore" disabled>
            </b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <b-field
            label="Ponderado dimensión RELACIONES INTERPERSONALES:"
            type="is-dark"
            message="Este resultado debe interpretarse en una escala de 0 a 100 puntos. Siendo 0 puntos la peor puntuacion RELACIONES INTERPERSONALES y 100 puntos la mejor"
          >
            <b-input type="text" v-model="socialRelationshipsScore" disabled>
            </b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <b-field
            label="Ponderado dimensión ENTORNO SOCIAL:"
            type="is-dark"
            message="Este resultado debe interpretarse en una escala de 0 a 100 puntos. Siendo 0 puntos la peor puntuacion ENTORNO SOCIAL y 100 puntos la mejor"
          >
            <b-input type="text" v-model="environmentScore" disabled> </b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="block has-text-centered title">
      El resultado es: {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { WHOQOL_BREF as questions } from "../../../data/questions";
import { WHOQOL_BREF as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      answerFive: "",
      answerSix: "",
      answerSeven: "",
      answerEight: "",
      answerNine: "",
      answerTen: "",
      answerEleven: "",
      answerTwelve: "",
      answerThirteen: "",
      answerFouthteen: "",
      answerFifteen: "",
      answerSixteen: "",
      answerSeventeen: "",
      answerEighteen: "",
      answerNineteen: "",
      answerTwenty: "",
      answerTwentyOne: "",
      answerTwentytwo: "",
      answerTwentyThree: "",
      answerTwentyFour: "",
      answerTwentyFive: "",
      answerTwentySix: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "WhoqlList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
      this.answerEight = this.$route.params.temporalData.answerEight;
      this.answerNine = this.$route.params.temporalData.answerNine;
      this.answerTen = this.$route.params.temporalData.answerTen;
      this.answerEleven = this.$route.params.temporalData.answerEleven;
      this.answerTwelve = this.$route.params.temporalData.answerTwelve;
      this.answerThirteen = this.$route.params.temporalData.answerThirteen;
      this.answerFouthteen = this.$route.params.temporalData.answerFouthteen;
      this.answerFifteen = this.$route.params.temporalData.answerFifteen;
      this.answerSixteen = this.$route.params.temporalData.answerSixteen;
      this.answerSeventeen = this.$route.params.temporalData.answerSeventeen;
      this.answerEighteen = this.$route.params.temporalData.answerEighteen;
      this.answerNineteen = this.$route.params.temporalData.answerNineteen;
      this.answerTwenty = this.$route.params.temporalData.answerTwenty;
      this.answerTwentyOne = this.$route.params.temporalData.answerTwentyOne;
      this.answerTwentytwo = this.$route.params.temporalData.answerTwentytwo;
      this.answerTwentyThree = this.$route.params.temporalData.answerTwentyThree;
      this.answerTwentyFour = this.$route.params.temporalData.answerTwentyFour;
      this.answerTwentyFive = this.$route.params.temporalData.answerTwentyFive;
      this.answerTwentySix = this.$route.params.temporalData.answerTwentySix;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return Math.round(
        (this.physicalScore +
          this.psychologicalScore +
          this.socialRelationshipsScore +
          this.environmentScore) /
          4
      );
    },
    physicalScore() {
      let points =
        parseInt(this.answerThree || 0) +
        parseInt(this.answerFour || 0) +
        parseInt(this.answerTen || 0) +
        parseInt(this.answerFifteen || 0) +
        parseInt(this.answerSixteen || 0) +
        parseInt(this.answerSeventeen || 0) +
        parseInt(this.answerEighteen || 0);

      let finalResult = 0;
      if (points == 7) {
        finalResult = 0;
      }

      if (points == 8 || points == 9) {
        finalResult = 6;
      }

      if (points == 10 || points == 11) {
        finalResult = 13;
      }

      if (points == 12 || points == 13) {
        finalResult = 19;
      }

      if (points == 14) {
        finalResult = 25;
      }

      if (points == 15 || points == 16) {
        finalResult = 31;
      }

      if (points == 17 || points == 18) {
        finalResult = 38;
      }

      if (points == 19 || points == 20) {
        finalResult = 44;
      }

      if (points == 21) {
        finalResult = 50;
      }

      if (points == 22 || points == 23) {
        finalResult = 56;
      }

      if (points == 24 || points == 25) {
        finalResult = 63;
      }

      if (points == 26 || points == 27) {
        finalResult = 69;
      }

      if (points == 28) {
        finalResult = 75;
      }

      if (points == 29 || points == 30) {
        finalResult = 81;
      }

      if (points == 31 || points == 32) {
        finalResult = 88;
      }

      if (points == 33 || points == 34) {
        finalResult = 94;
      }

      if (points == 35) {
        finalResult = 100;
      }

      return finalResult;
    },
    psychologicalScore() {
      let points =
        parseInt(this.answerFive || 0) +
        parseInt(this.answerSix || 0) +
        parseInt(this.answerSeven || 0) +
        parseInt(this.answerEleven || 0) +
        parseInt(this.answerNineteen || 0) +
        parseInt(this.answerTwentySix || 0);

      let finalResult = 0;
      if (points == 6) {
        finalResult = 0;
      }

      if (points == 7 || points == 8) {
        finalResult = 6;
      }

      if (points == 9) {
        finalResult = 13;
      }

      if (points == 10 || points == 11) {
        finalResult = 19;
      }

      if (points == 12) {
        finalResult = 25;
      }

      if (points == 13 || points == 14) {
        finalResult = 31;
      }

      if (points == 15) {
        finalResult = 38;
      }

      if (points == 16 || points == 17) {
        finalResult = 44;
      }

      if (points == 18) {
        finalResult = 50;
      }

      if (points == 19 || points == 20) {
        finalResult = 56;
      }

      if (points == 21) {
        finalResult = 63;
      }

      if (points == 22 || points == 23) {
        finalResult = 69;
      }

      if (points == 24) {
        finalResult = 75;
      }

      if (points == 25 || points == 26) {
        finalResult = 81;
      }

      if (points == 27) {
        finalResult = 88;
      }

      if (points == 28 || points == 29) {
        finalResult = 94;
      }

      if (points == 30) {
        finalResult = 100;
      }

      return finalResult;
    },
    socialRelationshipsScore() {
      let points =
        parseInt(this.answerTwenty || 0) +
        parseInt(this.answerTwentyOne || 0) +
        parseInt(this.answerTwentytwo || 0);

      let finalResult = 0;

      if (points == 3) {
        finalResult = 0;
      }

      if (points == 4) {
        finalResult = 6;
      }

      if (points == 5) {
        finalResult = 19;
      }

      if (points == 6) {
        finalResult = 25;
      }

      if (points == 7) {
        finalResult = 31;
      }

      if (points == 8) {
        finalResult = 44;
      }

      if (points == 9) {
        finalResult = 50;
      }

      if (points == 10) {
        finalResult = 56;
      }

      if (points == 11) {
        finalResult = 69;
      }

      if (points == 12) {
        finalResult = 75;
      }

      if (points == 13) {
        finalResult = 81;
      }

      if (points == 14) {
        finalResult = 94;
      }

      if (points == 15) {
        finalResult = 100;
      }

      return finalResult;
    },
    environmentScore() {
      let points =
        parseInt(this.answerEight || 0) +
        parseInt(this.answerNine || 0) +
        parseInt(this.answerTwelve || 0) +
        parseInt(this.answerThirteen || 0) +
        parseInt(this.answerFouthteen || 0) +
        parseInt(this.answerTwentyThree || 0) +
        parseInt(this.answerTwentyFour || 0) +
        parseInt(this.answerTwentyFive || 0);

      let finalResult = 0;

      if (points == 8) {
        finalResult = 0;
      }

      if (points == 9 || points == 10) {
        finalResult = 6;
      }

      if (points == 11 || points == 12) {
        finalResult = 13;
      }

      if (points == 13 || points == 14) {
        finalResult = 19;
      }

      if (points == 15 || points == 16) {
        finalResult = 25;
      }

      if (points == 17 || points == 18) {
        finalResult = 31;
      }

      if (points == 19 || points == 20) {
        finalResult = 38;
      }

      if (points == 21 || points == 22) {
        finalResult = 44;
      }

      if (points == 23 || points == 24) {
        finalResult = 50;
      }

      if (points == 25 || points == 26) {
        finalResult = 56;
      }

      if (points == 27 || points == 28) {
        finalResult = 63;
      }

      if (points == 29 || points == 30) {
        finalResult = 69;
      }

      if (points == 31 || points == 32) {
        finalResult = 75;
      }

      if (points == 33 || points == 34) {
        finalResult = 81;
      }

      if (points == 35 || points == 36) {
        finalResult = 88;
      }

      if (points == 37 || points == 38) {
        finalResult = 97;
      }

      if (points == 39 || points == 40) {
        finalResult = 100;
      }

      return finalResult;
    },
    isIncompleted() {
      return (
        !this.answerOne ||
        !this.answerTwo ||
        !this.answerThree ||
        !this.answerFour ||
        !this.answerFive ||
        !this.answerSix ||
        !this.answerSeven ||
        !this.answerEight ||
        !this.answerNine ||
        !this.answerTen ||
        !this.answerEleven ||
        !this.answerTwelve ||
        !this.answerThirteen ||
        !this.answerFouthteen ||
        !this.answerFifteen ||
        !this.answerSixteen ||
        !this.answerSeventeen ||
        !this.answerEighteen ||
        !this.answerNineteen ||
        !this.answerTwenty ||
        !this.answerTwentyOne ||
        !this.answerTwentytwo ||
        !this.answerTwentyThree ||
        !this.answerTwentyFour ||
        !this.answerTwentyFive ||
        !this.answerTwentySix
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFive = "";
      this.answerSix = "";
      this.answerSeven = "";
      this.answerEight = "";
      this.answerNine = "";
      this.answerTen = "";
      this.answerEleven = "";
      this.answerTwelve = "";
      this.answerThirteen = "";
      this.answerFouthteen = "";
      this.answerFifteen = "";
      this.answerSixteen = "";
      this.answerSeventeen = "";
      this.answerEighteen = "";
      this.answerNineteen = "";
      this.answerTwenty = "";
      this.answerTwentyOne = "";
      this.answerTwentytwo = "";
      this.answerTwentyThree = "";
      this.answerTwentyFour = "";
      this.answerTwentyFive = "";
      this.answerTwentySix = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSeven = this.answerSeven;
      this.restoreData.answerEight = this.answerEight;
      this.restoreData.answerNine = this.answerNine;
      this.restoreData.answerTen = this.answerTen;
      this.restoreData.answerEleven = this.answerEleven;
      this.restoreData.answerTwelve = this.answerTwelve;
      this.restoreData.answerThirteen = this.answerThirteen;
      this.restoreData.answerFouthteen = this.answerFouthteen;
      this.restoreData.answerFifteen = this.answerFifteen;
      this.restoreData.answerSixteen = this.answerSixteen;
      this.restoreData.answerSeventeen = this.answerSeventeen;
      this.restoreData.answerEighteen = this.answerEighteen;
      this.restoreData.answerNineteen = this.answerNineteen;
      this.restoreData.answerTwenty = this.answerTwenty;
      this.restoreData.answerTwentyOne = this.answerTwentyOne;
      this.restoreData.answerTwentytwo = this.answerTwentytwo;
      this.restoreData.answerTwentyThree = this.answerTwentyThree;
      this.restoreData.answerTwentyFour = this.answerTwentyFour;
      this.restoreData.answerTwentyFive = this.answerTwentyFive;
      this.restoreData.answerTwentySix = this.answerTwentySix;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog(
        "Se limpiaron los datos de  la encuesta WHOQOL-BREF ECV."
      );
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "WHOQOL_BREF",
          result: String(this.score),
          appreciation: "",
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question6 = null;
      let question7 = null;
      let question8 = null;
      let question9 = null;
      let question10 = null;
      let question11 = null;
      let question12 = null;
      let question13 = null;
      let question14 = null;
      let question15 = null;
      let question16 = null;
      let question17 = null;
      let question18 = null;
      let question19 = null;
      let question20 = null;
      let question21 = null;
      let question22 = null;
      let question23 = null;
      let question24 = null;
      let question25 = null;
      let question26 = null;

      Object.keys(this.answers[questions.WHOQOL_BREF_P1]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.WHOQOL_BREF_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P2]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.WHOQOL_BREF_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P3]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.WHOQOL_BREF_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P4]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.WHOQOL_BREF_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P5]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.WHOQOL_BREF_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P6]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.WHOQOL_BREF_P6,
            answerCode: key,
            value: this.answerSix,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P7]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.WHOQOL_BREF_P7,
            answerCode: key,
            value: this.answerSeven,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P8]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P8][key] == this.answerEight) {
          question8 = {
            questionCode: questions.WHOQOL_BREF_P8,
            answerCode: key,
            value: this.answerEight,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P9]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P9][key] == this.answerNine) {
          question9 = {
            questionCode: questions.WHOQOL_BREF_P9,
            answerCode: key,
            value: this.answerNine,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P10]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P10][key] == this.answerTen) {
          question10 = {
            questionCode: questions.WHOQOL_BREF_P10,
            answerCode: key,
            value: this.answerTen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P11]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P11][key] == this.answerEleven) {
          question11 = {
            questionCode: questions.WHOQOL_BREF_P11,
            answerCode: key,
            value: this.answerEleven,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P12]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P12][key] == this.answerTwelve) {
          question12 = {
            questionCode: questions.WHOQOL_BREF_P12,
            answerCode: key,
            value: this.answerTwelve,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P13]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P13][key] == this.answerThirteen
        ) {
          question13 = {
            questionCode: questions.WHOQOL_BREF_P13,
            answerCode: key,
            value: this.answerThirteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P14]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P14][key] == this.answerFouthteen
        ) {
          question14 = {
            questionCode: questions.WHOQOL_BREF_P14,
            answerCode: key,
            value: this.answerFouthteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P15]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P15][key] == this.answerFifteen
        ) {
          question15 = {
            questionCode: questions.WHOQOL_BREF_P15,
            answerCode: key,
            value: this.answerFifteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P16]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P16][key] == this.answerSixteen
        ) {
          question16 = {
            questionCode: questions.WHOQOL_BREF_P16,
            answerCode: key,
            value: this.answerSixteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P17]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P17][key] == this.answerSeventeen
        ) {
          question17 = {
            questionCode: questions.WHOQOL_BREF_P17,
            answerCode: key,
            value: this.answerSeventeen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P18]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P18][key] == this.answerEighteen
        ) {
          question18 = {
            questionCode: questions.WHOQOL_BREF_P18,
            answerCode: key,
            value: this.answerEighteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P19]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P19][key] == this.answerNineteen
        ) {
          question19 = {
            questionCode: questions.WHOQOL_BREF_P19,
            answerCode: key,
            value: this.answerNineteen,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P20]).forEach((key) => {
        if (this.answers[questions.WHOQOL_BREF_P20][key] == this.answerTwenty) {
          question20 = {
            questionCode: questions.WHOQOL_BREF_P20,
            answerCode: key,
            value: this.answerTwenty,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P21]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P21][key] == this.answerTwentyOne
        ) {
          question21 = {
            questionCode: questions.WHOQOL_BREF_P21,
            answerCode: key,
            value: this.answerTwentyOne,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P22]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P22][key] == this.answerTwentytwo
        ) {
          question22 = {
            questionCode: questions.WHOQOL_BREF_P22,
            answerCode: key,
            value: this.answerTwentytwo,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P23]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P23][key] == this.answerTwentyThree
        ) {
          question23 = {
            questionCode: questions.WHOQOL_BREF_P23,
            answerCode: key,
            value: this.answerTwentyThree,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P24]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P24][key] == this.answerTwentyFour
        ) {
          question24 = {
            questionCode: questions.WHOQOL_BREF_P24,
            answerCode: key,
            value: this.answerTwentyFour,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P25]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P25][key] == this.answerTwentyFive
        ) {
          question25 = {
            questionCode: questions.WHOQOL_BREF_P25,
            answerCode: key,
            value: this.answerTwentyFive,
          };
        }
      });

      Object.keys(this.answers[questions.WHOQOL_BREF_P26]).forEach((key) => {
        if (
          this.answers[questions.WHOQOL_BREF_P26][key] == this.answerTwentySix
        ) {
          question26 = {
            questionCode: questions.WHOQOL_BREF_P26,
            answerCode: key,
            value: this.answerTwentySix,
          };
        }
      });

      return [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question8,
        question9,
        question10,
        question11,
        question12,
        question13,
        question14,
        question15,
        question16,
        question17,
        question18,
        question19,
        question20,
        question21,
        question22,
        question23,
        question24,
        question25,
        question26,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;
      this.answerSix = this.restoreData.answerSix;
      this.answerSeven = this.restoreData.answerSeven;
      this.answerEight = this.restoreData.answerEight;
      this.answerNine = this.restoreData.answerNine;
      this.answerTen = this.restoreData.answerTen;
      this.answerEleven = this.restoreData.answerEleven;
      this.answerTwelve = this.restoreData.answerTwelve;
      this.answerThirteen = this.restoreData.answerThirteen;
      this.answerFouthteen = this.restoreData.answerFouthteen;
      this.answerFifteen = this.restoreData.answerFifteen;
      this.answerSixteen = this.restoreData.answerSixteen;
      this.answerSeventeen = this.restoreData.answerSeventeen;
      this.answerEighteen = this.restoreData.answerEighteen;
      this.answerNineteen = this.restoreData.answerNineteen;
      this.answerTwenty = this.restoreData.answerTwenty;
      this.answerTwentyOne = this.restoreData.answerTwentyOne;
      this.answerTwentytwo = this.restoreData.answerTwentytwo;
      this.answerTwentyThree = this.restoreData.answerTwentyThree;
      this.answerTwentyFour = this.restoreData.answerTwentyFour;
      this.answerTwentyFive = this.restoreData.answerTwentyFive;
      this.answerTwentySix = this.restoreData.answerTwentySix;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "WHOQOL BREF ECV",
          route: "Whoql",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerSix: this.answerSix,
          answerSeven: this.answerSeven,
          answerEight: this.answerEight,
          answerNine: this.answerNine,
          answerTen: this.answerTen,
          answerEleven: this.answerEleven,
          answerTwelve: this.answerTwelve,
          answerThirteen: this.answerThirteen,
          answerFouthteen: this.answerFouthteen,
          answerFifteen: this.answerFifteen,
          answerSixteen: this.answerSixteen,
          answerSeventeen: this.answerSeventeen,
          answerEighteen: this.answerEighteen,
          answerNineteen: this.answerNineteen,
          answerTwenty: this.answerTwenty,
          answerTwentyOne: this.answerTwentyOne,
          answerTwentytwo: this.answerTwentytwo,
          answerTwentyThree: this.answerTwentyThree,
          answerTwentyFour: this.answerTwentyFour,
          answerTwentyFive: this.answerTwentyFive,
          answerTwentySix: this.answerTwentySix,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "WHOQOL_BREF_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P5") {
          this.answerFive = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P6") {
          this.answerSix = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P7") {
          this.answerSeven = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P8") {
          this.answerEight = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P9") {
          this.answerNine = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P10") {
          this.answerTen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P11") {
          this.answerEleven = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P12") {
          this.answerTwelve = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P13") {
          this.answerThirteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P14") {
          this.answerFouthteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P15") {
          this.answerFifteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P16") {
          this.answerSixteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P17") {
          this.answerSeventeen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P18") {
          this.answerEighteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P19") {
          this.answerNineteen = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P20") {
          this.answerTwenty = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P21") {
          this.answerTwentyOne = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P22") {
          this.answerTwentytwo = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P23") {
          this.answerTwentyThree = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P24") {
          this.answerTwentyFour = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P25") {
          this.answerTwentyFive = survey.value;
        }

        if (survey.question.code == "WHOQOL_BREF_P26") {
          this.answerTwentySix = survey.value;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.WHOQOL_BREF_P1.WHOQOL_BREF_P1_R1;
      this.answerTwo = this.answers.WHOQOL_BREF_P2.WHOQOL_BREF_P2_R1;
      this.answerThree = this.answers.WHOQOL_BREF_P3.WHOQOL_BREF_P3_R5;
      this.answerFour = this.answers.WHOQOL_BREF_P4.WHOQOL_BREF_P4_R5;
      this.answerFive = this.answers.WHOQOL_BREF_P5.WHOQOL_BREF_P5_R1;
      this.answerSix = this.answers.WHOQOL_BREF_P6.WHOQOL_BREF_P6_R1;
      this.answerSeven = this.answers.WHOQOL_BREF_P7.WHOQOL_BREF_P7_R1;
      this.answerEight = this.answers.WHOQOL_BREF_P8.WHOQOL_BREF_P8_R1;
      this.answerNine = this.answers.WHOQOL_BREF_P9.WHOQOL_BREF_P9_R1;
      this.answerTen = this.answers.WHOQOL_BREF_P10.WHOQOL_BREF_P10_R1;
      this.answerEleven = this.answers.WHOQOL_BREF_P11.WHOQOL_BREF_P11_R1;
      this.answerTwelve = this.answers.WHOQOL_BREF_P12.WHOQOL_BREF_P12_R1;
      this.answerThirteen = this.answers.WHOQOL_BREF_P13.WHOQOL_BREF_P13_R1;
      this.answerFouthteen = this.answers.WHOQOL_BREF_P14.WHOQOL_BREF_P14_R1;
      this.answerFifteen = this.answers.WHOQOL_BREF_P15.WHOQOL_BREF_P15_R1;
      this.answerSixteen = this.answers.WHOQOL_BREF_P16.WHOQOL_BREF_P16_R1;
      this.answerSeventeen = this.answers.WHOQOL_BREF_P17.WHOQOL_BREF_P17_R1;
      this.answerEighteen = this.answers.WHOQOL_BREF_P18.WHOQOL_BREF_P18_R1;
      this.answerNineteen = this.answers.WHOQOL_BREF_P19.WHOQOL_BREF_P19_R1;
      this.answerTwenty = this.answers.WHOQOL_BREF_P20.WHOQOL_BREF_P20_R1;
      this.answerTwentyOne = this.answers.WHOQOL_BREF_P21.WHOQOL_BREF_P21_R1;
      this.answerTwentytwo = this.answers.WHOQOL_BREF_P22.WHOQOL_BREF_P22_R1;
      this.answerTwentyThree = this.answers.WHOQOL_BREF_P23.WHOQOL_BREF_P23_R1;
      this.answerTwentyFour = this.answers.WHOQOL_BREF_P24.WHOQOL_BREF_P24_R1;
      this.answerTwentyFive = this.answers.WHOQOL_BREF_P25.WHOQOL_BREF_P25_R1;
      this.answerTwentySix = this.answers.WHOQOL_BREF_P26.WHOQOL_BREF_P26_R5;
    },
  },
};
</script>
